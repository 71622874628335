
.image-sec{
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 
  height: 100vh;
  font-family: sans-serif;
  text-align: center;
  color: #fff;
}