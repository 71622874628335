.man-nav{
  
  display: flex;
flex-flow: row wrap;
/* This aligns items to the end line on main-axis */
justify-content: flex-end;

}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
 
}

/* li {
  float: left;
} */

li a {
  display: block;
 
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
/* li a:hover {
  background-color: #111;
} */



/* .tech-list:nth-child(odd)  {
  background-color: #1f1f53;
  color: #fff;
}


.tech-list:nth-child(even) {
  background-color: #ad00ad;
  color: #fff;
}
.tech-list:nth-child(3n) {
  background-color: #53d057;
  color: #fff;
}
.tech-list:nth-child(5n) {
  background-color: #9c0606;
  color: #fff;
}
.tech-list:nth-last-of-type(2) {
  background-color: #df6806;
  color: #fff;
}
.tech-list:nth-last-of-type(1) {
  background-color: #0644df;
  color: #fff;
} */

.tech-text{
  background-color: rgb(0, 148, 0) !important;
  color: #fff !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.card-list:nth-child(odd)  {
  background-color: #7f7faa;
  color: #fff;
}

.card-list:nth-child(even) {
  background-color: #926792;
  color: #fff;
}
.card-list:nth-child(3n) {
  background-color: #6b906c;
  color: #fff;
}
.card-list:nth-child(5n) {
  background-color: #b37575;
  color: #fff;
}
.card-list:nth-last-of-type(2) {
  background-color: #be8a5f;
  color: #fff;
}
.card-list:nth-last-of-type(1) {
  background-color: #8c9bbf;
  color: #fff;
}

.sun{
  position: relative;
  width: 100px;
  height: 100px;
  background-color: gold;
  border-radius: 50%;
  box-shadow: 0 0 50px gold;
  top:25%;

}
.earth{
  position: absolute;
  width: 50px;
  height: 50px;
 
  border-radius: 50%;
  top: 35%;
  left: 50%;
  transform-origin: top left;
  animation: rotate-earth 12s linear infinite;

}
.earth::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  /* border: 1px dashed rgba(255, 255, 255, 0.5); */
  box-sizing: border-box;
  transform-origin: 0 0 ;
  transform: translate(-50%, -50%);

}

.moon{
  position: absolute;
  width: 25px;
  height: 25px;
 
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  animation: rotate-moon 6s linear infinite;

}

@keyframes rotate-moon{
  0%{
    transform: rotate(0deg) translateX(30px);
  }

  100%{
    transform: rotate(360deg) translateX(30px);
  }
}

@keyframes rotate-earth{
  0%{
    transform: rotate(0deg) translateX(100px);
  }

  100%{
    transform: rotate(360deg) translateX(100px);
  }
}

